/** @jsx jsx */
import { Fragment, useCallback } from "react"
import { jsx, Flex, Box, Badge, Heading, Paragraph } from "theme-ui"

import Logo from "../components/Logo"
// import AppLinks from "../components/AppDownloadLinks"
import DiscordLink from "../components/DiscordLink"
import Layout from "../layout"

const badges = [
  "Mentoring",
  "Proof of Durable Skills",
  "Unbiased Job invites",
  "Guided skilling",
  "Quests",
  "Web3",
  "Grants $$",
]

const IndexPage = () => {
  const renderBadges = useCallback(
    () =>
      badges.map(badge => (
        <Badge mx={1} my={1} key={badge}>
          {badge}
        </Badge>
      )),
    []
  )

  return (
    <Fragment>
      <Layout title="Career Wallet made for Humans by Humans">
        <Flex
          py={5}
          sx={{
            textAlign: "center",
            flexDirection: "column",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo size={24} />
          <Box mt={4} mb={4} sx={{ maxWidth: [450] }}>
            <Heading as="h2" sx={{ fontWeight: 500, color: "#3c3b3b" }}>
              Be invited into jobs at the world's most forward-thinking
              businesses without applying or writing CVs.
            </Heading>
          </Box>
          <Flex
            sx={{
              maxWidth: [450],
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {renderBadges()} */}
          </Flex>
          {/* <DiscordLink />
          <Box mt={4} sx={{ maxWidth: [450] }}>
            <Paragraph>Top talent is built here! </Paragraph>
          </Box> */}
          {/* <AppLinks mt={4} /> */}
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default IndexPage
